.Collage-container {
    width: 80%;
    margin: 6rem auto;
    align-items: center;
    display: flex;
    height: 55vh;
    background: #ECF2F9;
}
.collage-left {
    width: 50%;
    margin: 2rem;
}
.collage-right {
    width: 50%;
    height: 100%;
    position: relative;
}
.collage-image {
    position: absolute;
    height: 100%;
    top: 5%;
}
.Collage-logo{
    width: 25vw;
}
.collage-wedge {
    height: 1rem;
    animation: jiggle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.collage-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #437FC7;
    font-family: Leckerli system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
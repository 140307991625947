*{
    scroll-behavior: smooth;
}
.footer {
    height: 100px;
    background-color: rgb(33,33,33);
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    color: white;
}

/* header */
.portfolio-logo{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
    font-size: 3rem;
    text-transform: capitalize;
    margin-bottom: 0;
}
.portfolio-header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.portfolio-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
}
li {
    padding: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.case-study {
    all: unset;
    border: 1px solid white;
    /* border-radius: 2rem; */
    background: none;
    color: white;
    font-size: 1.2rem;
    width: 30%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: .5rem 2rem .5rem 2rem;
}
.case-study:hover {
    background-color: white ;
    color:black;
    transition-duration: 300ms;
}
.contact-link {
    all: unset;
    font-weight: 300;
}
.contact-link:hover, .resume-link:hover {
    color: #095383;
    cursor: pointer;
}
.resume-link {
    display: flex;
    align-items: center;
}
.pdf-icon {
    padding-left: .5rem;
    max-height: 1.6rem;
}


/* Body */
.project-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin: 0 auto;
    margin-top: 2rem;
    width: 90%;
    flex-wrap: wrap;
}
.project-prototypes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin: 0 auto;
    margin-top: 2rem;
    width: 90%;
    flex-wrap: wrap;
}
.project {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 40vw;
    border-radius: .2rem;

}
.project-proto {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: auto;
    border-radius: .2rem;
}
.project:hover {
    cursor: pointer;
}

/* Footer */
.footer-container{
    margin-top: 3rem;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.portfolio-footer {
    width: 90%;
    display: flex;
}

/* ----- cards ----  */

/* skylight */
.skylight-card {
    background: linear-gradient(97.29deg, #080809 1.76%, #313131 98.48%);
    position: relative;
}
.popover-skylight {
    position: absolute;
    top: 5%;
    left: -15%;
    width: 120%;
    height: auto;
}

/* MiScheduling */
.MiScheduling-card {
    background: linear-gradient(97.29deg,#6db0eb 1.76%, #a7cbeb 98.48%);
    position: relative;
}
.mischeduling-custom {
    width: 85%;
}
/* MiBuild */
.MiBuild-card {
    background: linear-gradient(97.29deg,#006064 1.76%, #008c9e 98.48%);
    position: relative;
}
.mibuild-custom {
    width: 85%;
}

/* Bundazzle */
.bundazzle-card {
    position: relative;
    background: #3070420d;
}
.popover-bundazzle {
    position: absolute;
    top: 25%;
    width: 90%;
    height: auto;
}

/* Thirteenth */
.thirteenth-card {
    position: relative;
    background: #E7EDF1;
}
.popover-thirteenth {
    position: absolute;
    top: 20%;
    left: -25%;
    height: 75%;
    width: auto;
}

/* refcon */
.refcon-card {
    position: relative;
    background: #FDF5EC;
}
.popover-refcon {
    position: absolute;
    top: 5%;
    left: -10%;
    height: 100%;
    width: auto;
}



/* popover */
.popover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(0, 0, 0, 0.75);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}
.project:hover .popover {
    opacity: 1;
    height: 100%;
}
.project-title-popover {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3rem;
    font-weight: 300;
    margin: 1rem;
}
.project-description-popover {
    width: 80%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 200;
    font-size: 1.3rem;
    color:#dddddd;
    margin-bottom: 3rem;
}
.pd-graphic {
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.graphic-section{
    width: 90%;
    margin: 0 auto;
}
.divider {
    margin-top: 6rem;
    height: 1rem;
    width: 100vw;
    background-color: #080809;
}
.header-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}
.section-subtitle{
    font-size: 1.2rem;
    font-weight: 200;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
}
.section-header-title {
    font-size: 2.5rem;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: black;
    margin: 0;
    padding: 0;
}
.section-header-design {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.discover-wrapper {
    display: flex;
    align-items: center;
}
.discover-button{
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 200;
    font-size: 1.2rem;
    padding-right: .5rem;
    cursor: pointer;
}
.wedge {
    animation: slide 2000ms infinite;
}
@keyframes slide {
    0% {
       transform: translateX(0) ; 
    }
    50% {
        transform: translateX(.5rem);
    }
    100% {
        transform: translateX(0) ; 
     }
}


.gallery-grid {
    width: 90%;
    margin: 0 auto;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-gap:.5rem;
    grid-template-columns: 1fr;
}
.grid-item {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.gallery-section-grid {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: .5rem;
    width: 90%;
    margin: 0 auto;
}
.design-container {
    background-color: rgb(70, 144, 71);
    width: 100%;
    aspect-ratio: 1/.9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery-tile {
    width: 85%;
}
.gallery-tile:hover {
    cursor: pointer;
}
.prototypes {
    height: 90%;
}
.prototype-card{
    background-color: #2897d894;
}
.prototype-skylight{
    background-color: rgb(23, 23, 23)
}
.prototypes-skylight{
    width: 90%;
}
.second {
    background-color: #FEB901;
}
.third {
    background-color: #095383;
}


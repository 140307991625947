HTML CSSResult Skip Results Iframe

body {
  margin:0;
}

.name {
  font-family: 'Lato', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:4rem;
  margin: .5rem;
}
.bottom {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
}

#header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, var(--main-color) 0%, var(--secondary-color) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  .name {
    font-size:4rem;
  }
}

.social-icon {
  margin: .5rem;
}
.social {
  width: 50px;
}
.mail {
  filter: invert(100%);
  width: 42px;
}
.arrow {
  transform: scale(.7);
}
.downarrow {
  opacity: .8;
}
.downarrow:hover{
  transform: scale(1.2);
  transition: .2s;
  opacity: 1;
}
.links {
  padding: .5rem;
}
.link {
  text-decoration: none;
  margin: 1rem;
  color: white;
  font-size: 1.5rem;
}
.link-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.job {
  font-size: 1.2rem;
  margin-top: .25rem;
}
.ThirteenthFloor-container {
    width: 80%;
    margin: 6rem auto;
    align-items: center;
    display: flex;
    height: 55vh;
    background: #E7EDF1;
}
.ThirteenthFloor-logo {
    width: 25vw;
}
.right-13 {
    width: 50%;
    height: 100%;
    position: relative;
}
.left-13 {
    width: 50%;
    margin: 2rem;
}
.ThirteenthFloor-image {
    position: absolute;
    height: 55%;
    top: 25%;
}
.floor-wedge {
    height: 1rem;
    animation: jiggle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.th-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #437FC7;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.bundazzle-container {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.left-bund {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.pointer {
    max-height: 90vh;
    max-width: 100%;
}
.right-bund {
    width: 60%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}
.line {
    width: 100%;
    height: 1px;
    background: #000;
}
.bundazzle-logo{
    width: 30vw;
}
.stars {
    margin-top: 1rem;
    width: 20vw;
    min-width: 250px;
}
.bundazzle-app {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: auto;
}
.casestudy-button {
    background: #05E745;
    color: white;
    border: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 3rem;
    font-size: 2rem ;
    box-shadow: 2px 2px 5px 0px #a3a3a3;
    animation: buttonPulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
}

@keyframes buttonPulse {
    0% { scale: 1}
    50% { scale: 1.03}
    100% {scale: 1}

}
#about {
    z-index: 100;
    color: black;
}
.person-container {
    display: flex;
    flex-direction: column;
    justify-content: center ;
    align-items: center;
    margin: 0 auto;
}
.headshot {
    width: 150px;
    border-radius: 500px;
}
.mortarboard {
    height: 20px;
    padding-right: .5rem;
}
.hi{
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    margin: .45rem;
}
.work-education-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.education-container {
    display:flex;
    align-items: center;
}
.education {
    margin: .2rem;
    font-family: "Lato";
}

.skills-wrapper {
    width: 70vw;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    
}
.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
}
.skills-row{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.skill, .skill-title {
    text-align: center;
}
.skill-title {
    font-size: 1.2rem;
    font-family: 'Lato';
    font-weight: 300;
}
.skill {
    padding-top: .5rem;
    font-family: "Lato", sans-serif;
    padding-bottom: .5rem;
    color: rgb(74, 74, 74);
}
@media screen and (max-width: 450px) {
    .skills-wrapper {
        flex-direction: column;
    }
    .skills {
        margin: 1rem;
    }


}

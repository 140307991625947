.full-page {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.construction-image {
  width: 30vw;
  height: auto;
}
.construction-description {
  width: 50vw;
  text-align: center;
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 3rem;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #1f1f1f;
}
.construction-button {
  width: 30vw;
  height: 4rem;
  color: #1f1f1f;
  background: none;
  font-size: 2rem;
  border-radius: 1rem;
  border-color: #1f1f1f;
}
.construction-button:hover{
  color: #ffffff;
  background-color: #1f1f1f;
  transition: 300ms ease-in-out;
}
